


























import { Component, Vue } from 'vue-property-decorator';
import AuthService from '@/common/services/auth-service';
import displayMessage from '@/common/functions/display-message';

@Component
export default class ForgotPasswordForm extends Vue {
  authService!: AuthService;

  email = '';

  isLoading = false;

  created(): void {
    this.authService = new AuthService();
  }

  async forgotPassword() {
    this.isLoading = true;

    const message = await this.authService.forgotPassword(this.email);

    displayMessage(message.message, message.isError, this.$store);

    this.isLoading = false;
  }
}
